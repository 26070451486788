<template>
    <div class="share">
        <van-nav-bar
            title="分享好友"
            fixed
            left-arrow
            @click-left="$router.go(-1)"
        />
        <div class="box">
            <div class="bg"></div>
            <div class="color-blue avatar">
                <van-icon name="friends-o" />
            </div>
            <div class="address">
            </div>
            <!-- <vue-qr :correctLevel="3" :autoColor="false" colorDark="#127afe" :text="shareUrl" :size="120" :margin="0" :logoMargin="3"></vue-qr> -->
            <div class="code">
                <vue-qr v-if="shareUrl" :correctLevel="3" :autoColor="false" :text="shareUrl" :size="200" :margin="10" :logoMargin="5" />
            </div>
            <div>
                长按保存
            </div>
            <div class="or">
                <span class="dash"></span>
                <span class="ortext">或</span>
                <span class="dash"></span>
            </div>
            <div>
                复制链接
            </div>
            <div class="copyurl">
                <div class="url">
                    {{shareUrl}}
                </div>
                <div class="copy" @click="copyLink()">
                    <span class="wsicon wsicon-fuzhi"></span>
                </div>
            </div>
        </div>
    
    </div>
      
    </template>
    <script>
    import { Base64 } from 'js-base64';
    import VueQr from 'vue-qr';
    import config from '../config';
    import { Toast } from 'vant';
    export default {
      name: "Share",
      data() {
        return {
          shareUrl: '',
        };
      },
      components:{
          VueQr
      },
      mounted() {
        if(this.$store.state.userinfo.id){
            let _s = Base64.encode('u'+this.$store.state.userinfo.id);
            let _url = config._BASE_URL+'r/'+_s;
            this.shareUrl = _url;
        }else{
            this.$router.push({name:'login'})
        }
            
      },
      methods: {
        getData(){
            console.log(this.shareUrl)
        },
        copyLink(){
          let oInput = document.createElement('input');
          oInput.value = this.shareUrl;
          document.body.appendChild(oInput);
          oInput.select(); // 选择对象;
          console.log(oInput.value)
          document.execCommand("Copy"); // 执行浏览器复制命令
          Toast("已复制");
          oInput.remove();
        },
      },
    };
    </script>
    <style lang="less" scoped>
    .share{
        padding: 70px 15px;
        // width: 100%;
        .box {
            background: #fff;
            border-radius: 15px;
            margin: 60px 15px;
            padding: 20px 20px 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333;
            line-height: 40px;
            .avatar{
                background: #fff;
                border-radius: 50%;
                position: relative;
                top: -60px;
                width: 80px;height: 80px;
                font-size: 40px;
                margin-bottom: -50px;
                padding: 10px;
            }
            .code{
                width: 200px;height:200px;
            }
            .or{
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .dash{
                    margin-top: 20px;
                    flex: 1;
                    border-top: 1px dashed #dadada;
                }
                .ortext{
                    padding: 0 10px;
                }
            }
            .copyurl{
                width: 100%;
                display: flex;
                align-items: center;
                background: #d8d8d8;
                padding: 7px;
                border-radius: 7px;
                .url{
                    overflow-x: scroll;
                    white-space: nowrap;
                }
                .copy{
                    padding-left: 7px;
                }
            }
            
        }
    }
    
    </style>
    